<template>
  <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
    <a href="#" class="flex-shrink-0 group block">
      <div class="flex items-center">
        <div class="ml-3">
          <p class="text-base font-medium text-gray-700 group-hover:text-gray-900">
            App version {{version}}
          </p>
          <p class="font-mono tracking-tighter text-xs font-medium text-gray-400 group-hover:text-gray-700">
            &copy; {{year}} McCoy - Made in NL
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import {version} from '../../package.json'
import moment from 'moment'

export default {
  name: 'AppMenuFooter',
  data() {
    return {
      version,
      year: moment().format('YYYY')
    }
  }
}
</script>
